import { callWithNuxt, defineNuxtRouteMiddleware, navigateTo, useNuxtApp } from 'nuxt/app';
import { convertSearchParamsToString } from '../helpers/search.helper';
import { TABS } from '../interfaces/filters.interface';
import { WIDGET_TYPE } from '../interfaces/widget.interface';

// TODO - add Test Unit for each rewrite rule
const redirectWidget = defineNuxtRouteMiddleware(async (to) => {
  const nuxtApp = useNuxtApp()

  const widgetType = Array.isArray(to.params.widget_type)
    ? to.params.widget_type[0]
    : to.params.widget_type
  const redirWidgetType = Object.values(WIDGET_TYPE).includes(widgetType as WIDGET_TYPE)
    ? widgetType
    : WIDGET_TYPE.CAROUSEL

  const searchTabParam = Array.isArray(to.params.search_tab)
    ? to.params.search_tab[0]
    : to.params.search_tab

  const searchTabQuery = Array.isArray(to.query.tab)
    ? to.query.tab[0]
    : to.query.tab

  const searchTab = searchTabQuery || searchTabParam

  const redirSearchTab = Object.values(TABS).includes(searchTab as TABS)
    ? searchTab
    : TABS.ORGANIZATIONS

  const redirect = widgetType !== redirWidgetType || searchTab !== redirSearchTab || !!searchTabQuery

  if (redirect) {

    delete to.query.tab
    let queryString = convertSearchParamsToString(to.query)
    queryString = queryString ? `?${queryString}` : ''

    return await callWithNuxt(nuxtApp, () => {
      return navigateTo(`/e/widget/${redirWidgetType}/${redirSearchTab}${queryString}`)
    })
  }
})

export default redirectWidget